@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap");

.card {
  box-sizing: border-box;
  padding: 15px;
  min-width: 200px;
  max-width: 240px;
  width: 40%;
  background: white;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.7);
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  margin: 10px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.52);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.6px);
  -webkit-backdrop-filter: blur(11.6px);
  border: 1px solid rgba(255, 255, 255, 0.47);
}

.coverphoto {
  height: 180px;
  border-radius: 50%;
}

.profile {
  width: 65%;
  border-radius: 50%;
  border: 2px solid #222;
}

.profilename {
  font-size: 20px;
  color: black;
  margin: 5dvh;
}

.about {
  font-size: 18px;
  line-height: 1.6;
  color: black;
}

.about a {
  text-decoration: none;
  color: black;
}
