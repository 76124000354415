.navbar {
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 100;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 200px;
}

.logo > img {
  width: 30px;
  height: auto;
}

.links a {
  margin: 10px;
  text-decoration: none;
  color: white;
  transition: 0.3s ease-in;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
}

.links a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: white;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.links a:hover:after {
  width: 100%;
  left: 0;
}

@media screen and (max-width: 600px) {
  .links {
    visibility: hidden;
  }
}
