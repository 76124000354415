.register {
  margin-top: 30px;
  width: 90%;
  text-align: center;
  box-sizing: border-box;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(255, 255, 255, 0.47);
}

.innerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.form {
  width: 45%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input,
select {
  width: 70%;
  /* min-width: 200px; */
  height: 40px;
  border: 1px solid grey;
  border-radius: 8px;
  margin: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 5px;
  padding-left: 10px;
}

input:focus,
select:focus {
  outline: none;
}

button {
  border: none;
  background-color: white;
  width: 80px;
  height: 40px;
  border-radius: 8px;
  color: blue;
  font-weight: 900;
}

.guidebox {
  width: 45%;
  min-width: 250px;
}

.guidelines {
  font-size: 20px;
  text-align: start;
}

.btnGroup {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.tel input {
  padding-left: 35px;
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 0px;
  width: 100%;
}

.tel {
  width: 70%;
  height: 40px;
  position: relative;
  margin: 5px;
  margin-bottom: 10px;
}

.countryCode {
  position: absolute;
  color: black;
  top: 8px;
  left: 5px;
  font-weight: 600;
  font-size: 14px;
  box-sizing: border-box;
  padding: 3px;
  border-right: 1px solid #7e7e7e;
}
