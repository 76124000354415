.sponsors {
  margin: 30px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(255, 255, 255, 0.47);
}

.sponsorsList {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
