.app {
  background: url("../assets/WALL.png");
  background-repeat: no-repeat;
  /* background-attachment: scroll; */
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  color: white;
  overflow: auto;
}

.app::-webkit-scrollbar {
  display: none;
  width: 0px;
}
