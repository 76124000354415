.aboutUs {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: calc(100vh - 80px);
}

.leftAbout {
  width: 45%;
  min-width: 250px;
  text-align: center;
  margin: 20px;
  font-weight: bolder;
  color: rgb(240, 237, 237);
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #f8ff37,
      0 0 40px #f8ff37, 0 0 50px #f8ff37, 0 0 60px #f8ff37, 0 0 70px #f8ff37;
  }
  50% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #63f850,
      0 0 40px #63f850, 0 0 50px #63f850, 0 0 60px #63f850, 0 0 70px #63f850;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
      0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

.leftAbout h1 {
  margin: 5px;
  font-size: 9vw;
  -webkit-animation: glow 5s ease-in-out infinite alternate;
  -moz-animation: glow 5s ease-in-out infinite alternate;
  animation: glow 5s ease-in-out infinite alternate;
}

.leftAbout h2 {
  margin: 0px;
  font-size: 35px;
}

.leftAbout h3 {
  margin: 5px;
}
.leftAbout img {
  height: 250px;
}

.rightAbout {
  width: 90%;
  text-align: center;
  min-width: 250px;
  box-sizing: border-box;
  margin: 20px;
  padding: 20px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(255, 255, 255, 0.47);
}

.desc {
  font-size: 20px;
}

@media screen and (max-width: 600px) {
  .leftAbout {
    width: 90%;
  }
  .rightAbout {
    width: 90%;
  }
}
